import React from 'react';
import {ChakraProvider, Box, Heading, Text, VStack, Button} from '@chakra-ui/react';
import {AiFillInstagram} from "react-icons/ai";

const Announcement = () => {
    return (
            <Box
                bg="yellow.300"
                color="black"
                p={4}
                mb={4}
                rounded="md"
                boxShadow="lg"
                textAlign="center"
            >
                <Heading fontSize="2xl">ZEON MOTO'yu Takipte Kalın</Heading>
                <Text mt={4}>
                    Yeni özelliklerimizle ilgili size heyecan verici bir şeyler paylaşmak
                    üzere buradayız. Takipte kalın!
                </Text>
                <Button bg={'black'} color={"yellow.300"} leftIcon={<AiFillInstagram />}
                        _hover={{
                            bg: "gray.700",
                            color: "yellow",
                        }}
                        onClick={() => {
                            window.open("https://www.instagram.com/zeonmotomarket/", "_blank");
                        }}
                >
                    zeonmotomarket
                </Button>
            </Box>
    );
};

export default Announcement;
