import React from "react";
import {
    Box, Button,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Link,
    Skeleton,
    Stack,
    useColorModeValue, VStack,
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa';
import zeonDukkan from "./assets/zeon_dukkan.jpg"
import zeonDukkanOn from "./assets/zeon_dukkan_on.jpg";
import foto1 from "./assets/foto1.jpg";
import foto2 from "./assets/foto2.jpg";
import foto3 from "./assets/foto3.jpg";
import Announcement from "./Announcement";
import {AiFillInstagram} from "react-icons/ai";

const Home = ({changeRoute}) => {
    return (
        <>

            <Announcement />
            <Box px={{ base: '0', lg: '12' }} py={{ base: '0', lg: '12' }}>
                <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '0', lg: '20' }}>
                    <Box
                        width={{ lg: 'sm' }}
                        bg={{ base: useColorModeValue('red.50', 'gray.700'), lg: 'transparent' }}
                        mx={{ base: '6', md: '8', lg: '0' }}
                        px={{ base: '6', md: '8', lg: '0' }}
                        py={{ base: '6', md: '8', lg: '12' }}
                        rounded={"lg"}
                        shadow={{ base: 'lg', lg: 'none' }}
                    >
                        <Stack spacing={{ base: '8', lg: '10' }}>
                            <Stack spacing={{ base: '2', lg: '4' }}>
                                <Heading size="xl" color={useColorModeValue('yellow.500', 'yellow.300')}>
                                    Zeon Moto
                                </Heading>
                                <Heading size="xl" fontWeight="normal">
                                    Yedek Parça İçin En Doğru Adres.
                                </Heading>
                            </Stack>
                            <VStack spacing="3" alignItems={"start"}>
                                <Button colorScheme={"yellow"} rightIcon={<FaArrowRight />} onClick={() => changeRoute("/catalog")}>
                                    Kataloğu İncele
                                </Button>
                                <Button colorScheme={"yellow"} rightIcon={<FaArrowRight />} onClick={() => changeRoute("/contact")}>
                                    İletişim
                                </Button>
                                <Button bg={'black'} color={"yellow.300"} leftIcon={<AiFillInstagram />}
                                        _hover={{
                                            bg: "gray.700",
                                            color: "yellow",
                                        }}
                                        onClick={() => {
                                            window.open("https://www.instagram.com/zeonmotomarket/", "_blank");
                                        }}
                                >
                                    zeonmotomarket
                                </Button>
                            </VStack>
                        </Stack>
                    </Box>

                </Stack>
            </Box>
        </>
    );
}
export default Home;