import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer, IconButton, Button, AlertDescription, AlertTitle, AlertIcon, Alert,
} from '@chakra-ui/react'
import React from "react";
import {Context, ContextConsumer} from "./Context";
import {AiOutlineDelete} from "react-icons/ai";
import style from "./Catalog.module.css"
const Basket = () => {
    const {basket,locked} = React.useContext(Context);
    const [total, setTotal] = React.useState(0);
    React.useEffect(() => {
        let total = 0;
        basket.forEach((item) => {
            total += item.price * item.quantity;
        });
        setTotal(total);
    }, [basket]);
    const approveBasket = () => {
        let stringBasket = "Merhaba Zeon Moto,\nSipariş vermek istiyorum.\n\nÜrünlerim:\n";
        basket.forEach((item) => {
            stringBasket += "*"+item.sku + "* -> " + item.quantity + " ADET\n";
        });
        stringBasket += "Sipariş Listem Bu Kadar. Dönüşünüzü Bekliyorum. Teşekkürler.";
        let url = "https://wa.me/905070004777?text=" + encodeURIComponent(stringBasket);
        window.open(url, "_blank");
    }
    return <>
        {basket.length > 0 ? <TableContainer>
            <Table variant='simple'>
                <TableCaption>Sepetinizi Onaylayarak Hızlı Sipariş Verebilirsiniz.</TableCaption>
                <Thead>
                    <Tr>
                        <Th>Görsel</Th>
                        <Th>Ürün Kodu</Th>
                        <Th>Ürün Adı</Th>
                        <Th textAlign={"center"}>Ürün Adedi</Th>
                        <Th textAlign={"center"}>Ürün Fiyatı</Th>
                        <Th textAlign={"center"}>Ara Toplam</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <ContextConsumer>
                        {({basket,removeBasket}) => {
                            return basket.map((item) => {
                                return <Tr key={item.sku}>
                                    <Td><img src={item.image} alt={item.name} width={50}/></Td>
                                    <Td>{item.sku}</Td>
                                    <Td>{item.name}</Td>
                                    <Td textAlign={"center"}>{item.quantity}</Td>
                                    <Td textAlign={"center"} className={locked ? style.hiddenPrice :""}>{item.price} ₺</Td>
                                    <Td textAlign={"center"} className={locked ? style.hiddenPrice :""}>{(item.price * item.quantity).toFixed(2)} ₺</Td>
                                    <Td>
                                        <IconButton aria-label={"Remove"} colorScheme={"red"} icon={<AiOutlineDelete/>} onClick={() => {
                                            removeBasket(item.sku);
                                        }} />
                                    </Td>
                                </Tr>
                            });
                        }}
                    </ContextConsumer>
                    <Tr>
                        <Td colSpan={4}></Td>
                        <Td textAlign={"center"}><b>Toplam Tutar</b></Td>
                        <Td textAlign={"center"} className={locked ? style.hiddenPrice :""}>{total.toFixed(2)} ₺</Td>
                    </Tr>
                    <Tr></Tr>
                    <Tr>
                        <Td colSpan={7} textAlign={"center"}>
                            <Button colorScheme={"green"} onClick={approveBasket} aria-label={"Onayla"}>Onayla</Button>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>:<Alert status='warning'>
            <AlertIcon />
            <AlertTitle>Sepetiniz Boş</AlertTitle>
            <AlertDescription>Sepetinize Ürün Ekleyerek Hızlı Sipariş Verebilirsiniz.</AlertDescription>
        </Alert>}
    </>;
}
export default Basket;