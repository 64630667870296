import React, {useEffect} from 'react';
import {
    ChakraProvider,
    Box,
    Flex,
    Spacer,
    Heading,
    Button,
    IconButton,
    useDisclosure,
    VStack,
    HStack,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Center,
    PinInput,
    PinInputField,
    Text,
    ModalFooter, Modal, useToast,
} from '@chakra-ui/react';
import {AiFillInstagram} from "react-icons/ai";
import {BiBasket} from "react-icons/bi";
import {GiHamburgerMenu} from "react-icons/gi";
import Catalog from "./Catalog";
import Helper from "./Helper";
import Home from "./Home";
import Contact from "./Contact";
import zeonLogo from "./assets/zeon.png";
import {Context, ContextConsumer, ContextProvider} from "./Context";
import Basket from "./Basket";
import {HiLockClosed, HiLockOpen} from "react-icons/hi";

const Menu = () => {
    const {isOpen, onToggle, onClose} = useDisclosure();
    const [route, setRoute] = React.useState(window.location.pathname);
    const [products, setProducts] = React.useState([]);
    const [logoImg, setLogoImg] = React.useState("");
    const changeRoute = (route) => {
        setRoute(route);
        onClose();
        window.history.pushState({}, "", route);
    }
    React.useEffect(() => {
        Helper.getActiveProducts().then((data) => {
            setProducts(data.products);
            setLogoImg(data.default_image);
        });
    }, []);

    const [basket, setBasket] = React.useState(JSON.parse(localStorage.getItem("basket")) || []);
    const [controlBasket, setControlBasket] = React.useState({});
    useEffect(() => {
        localStorage.setItem("basket", JSON.stringify(basket));
    },[basket]);
    const addBasket = (product) => {
        let index = basket.findIndex((item) => item.sku === product.sku);
        if (index !== -1) {
            basket[index].quantity++;
        } else {
            basket.push({...product, quantity: 1});
        }
        setBasket([...basket]);
    }
    const removeBasket = (sku) => {
        let index = basket.findIndex((item) => item.sku === sku);
        if (index !== -1) {
            basket.splice(index, 1);
            setBasket([...basket]);
        }
    }
    const updateQuantity = (action,sku, quantity=1) => {
       if(action === "getQuantity"){
           return controlBasket[sku];
       }else if(action === "updateValue"){
           let index = basket.findIndex((item) => item.sku === sku);
           if (index !== -1) {
               basket[index].quantity = quantity;
               setBasket([...basket]);
           }
       }else if(action === "increment"){
              let index = basket.findIndex((item) => item.sku === sku);
              if (index !== -1) {
                basket[index].quantity++;
                setBasket([...basket]);
              }
       }else if(action === "decrement"){
              let index = basket.findIndex((item) => item.sku === sku);
              if (index !== -1) {
                if(basket[index].quantity > 1){
                     basket[index].quantity--;
                     setBasket([...basket]);
                }
              }
       }else if(action === "remove"){
           removeBasket(sku);
       }

    }
    const [locked, setLocked] = React.useState(localStorage.getItem("locked") ? localStorage.getItem("locked") : true);
    useEffect(() => {
        let control = {};
        basket.forEach((item) => {
            control[item.sku] = item.quantity;
        });
        setControlBasket(control);
        console.log(basket)
    },[basket]);
    const lockModal = useDisclosure();
    const [errorMode, setErrorMode] = React.useState({
        status: false,
        message: "",
    });
    React.useEffect(() => {
        setLocked(localStorage.getItem("locked") ? localStorage.getItem("locked") : true);
    }, []);
    const toast = useToast();
    return (<ContextProvider value={{
            basket,
            setBasket,
            controlBasket,
            addBasket,
            removeBasket,
            updateQuantity,
            locked,
            setLocked
        }}>
            <ChakraProvider>
                <IconButton aria-label={"Güvenlik Kilidi"}
                            icon={locked ? <HiLockClosed/> : <HiLockOpen/>}
                            onClick={() => {
                                if (locked === false) {
                                    localStorage.setItem("locked", true);
                                    setLocked(true);
                                    toast({
                                        title: "Başarılı",
                                        description: "Erişim Kilidi Kapatıldı",
                                        status: "success",
                                        isClosable: true,
                                    })
                                } else {
                                    lockModal.onOpen();
                                }
                            }}
                            position={"fixed"} bottom={"10px"}
                            left={"10px"} zIndex={"99999999"} rounded={"full"}
                            colorScheme={locked ? "red" : "green"} size={"lg"}/>
                <Modal isOpen={lockModal.isOpen} onClose={lockModal.onClose} size={"xs"}>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Erişim Kilidini Açın</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            <Center>
                                <HStack>
                                    <PinInput isInvalid={errorMode.status} size='lg' mask colorScheme={"yellow"}
                                              autoFocus={true}
                                              onChange={() => {
                                                  setErrorMode({
                                                      status: false,
                                                      message: "",
                                                  })
                                              }}
                                              onComplete={(value) => {
                                                  if (value === process.env.REACT_APP_PASSWORD) {
                                                      setLocked(false);
                                                      localStorage.setItem("locked", false);
                                                      lockModal.onClose();
                                                      toast({
                                                          title: "Başarılı",
                                                          description: "Erişim Kilidi Açıldı",
                                                          status: "success",
                                                          isClosable: true,
                                                      })
                                                  } else {
                                                      setErrorMode({
                                                          status: true,
                                                          message: "Hatalı Şifre",
                                                      });
                                                  }
                                              }}
                                    >
                                        <PinInputField/>
                                        <PinInputField/>
                                        <PinInputField/>
                                        <PinInputField/>
                                    </PinInput>
                                </HStack>
                            </Center>
                            <Center>
                                {errorMode.status && (<Text color={"red"}>{errorMode.message}</Text>)}</Center>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme='blackAlpha' mr={3} onClick={lockModal.onClose}>
                                Vazgeç
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <Box bg="yellow.500" p={4}>
                    <Flex>
                        <IconButton
                            icon={<GiHamburgerMenu/>}
                            onClick={onToggle}
                            display={{md: 'none', base: 'flex'}}
                            colorScheme={"orange"}
                            mr={4}
                            aria-label={""} fontSize={"lg"} justifyContent={"center"} alignItems={"center"}/>
                        <Heading size="md" color="black" display={"flex"} alignItems={"center"} gap={3}
                                 fontWeight={"semibold"}>
                            <img src={zeonLogo}
                                 alt="LOGO"
                                 style={{
                                     height: "40px",
                                     filter: "invert(1) grayscale(1)",
                                 }}
                            />
                            <span>ZEON MOTO</span>
                        </Heading>
                        <Spacer/>
                        <HStack spacing={4} display={{base: 'none', md: 'flex'}}>
                            <Button bg={'black'} color={"yellow.300"} leftIcon={<AiFillInstagram/>}
                                    _hover={{
                                        bg: "gray.700",
                                        color: "yellow",
                                    }}
                                    onClick={() => {
                                        window.open("https://www.instagram.com/zeonmotomarket/", "_blank");
                                    }}
                            >
                                zeonmotomarket
                            </Button>
                            <Button colorScheme="yellow" onClick={() => changeRoute("/")}>Ana Sayfa</Button>
                            <Button colorScheme="yellow" onClick={() => changeRoute("/catalog")}>Katalog</Button>
                            <Button colorScheme="yellow" onClick={() => changeRoute("/contact")}>İletişim</Button>
                            <Button leftIcon={<BiBasket />} onClick={() => changeRoute("/basket")} colorScheme="yellow">
                                Sepet <ContextConsumer>
                                {({basket}) => (basket.length > 0 ? `(${basket.length})` : "")}
                            </ContextConsumer>
                            </Button>
                        </HStack>
                    </Flex>

                    {/* Responsive Navigation Drawer */}
                    {isOpen && (
                        <VStack
                            pos="absolute"
                            top="60px"
                            left="0"
                            right={"0"}
                            p={2}
                            bg="yellow.500"
                            roundedBottomRight={"md"}
                            zIndex={10}
                        >
                            <Button bg={'black'} w="100%" color={"yellow.300"} leftIcon={<AiFillInstagram/>}
                                    _hover={{
                                        bg: "gray.700",
                                        color: "yellow",
                                    }}
                                    onClick={() => {
                                        window.open("https://www.instagram.com/zeonmotomarket/", "_blank");
                                    }}
                            >
                                zeonmotomarket
                            </Button>
                            <Button onClick={() => changeRoute("/")} colorScheme="yellow" w="100%">
                                Ana Sayfa
                            </Button>
                            <Button onClick={() => changeRoute("/catalog")} colorScheme="yellow" w="100%">
                                Katalog
                            </Button>
                            <Button onClick={() => changeRoute("/contact")} colorScheme="yellow" w="100%">
                                İletişim
                            </Button>
                            <Button leftIcon={<BiBasket />} onClick={() => changeRoute("/basket")} colorScheme="yellow" w="100%">
                                Sepet <ContextConsumer>
                                {({basket}) => (basket.length > 0 ? `(${basket.length})` : "")}
                            </ContextConsumer>
                            </Button>
                        </VStack>
                    )}
                </Box>
                <Box p={4} overflowX={"hidden"} overflowY={"auto"} height={"calc(100vh - 80px)"}>
                    {route === "/" && <Home changeRoute={changeRoute}/>}
                    <Box hidden={route !== "/catalog"}>
                        <Catalog products={products} logoImg={logoImg}/>
                    </Box>
                    {route === "/contact" && <Contact/>}
                    {route === "/basket" && <Basket />}
                </Box>
            </ChakraProvider>
        </ContextProvider>
    );
};

export default Menu;