import {
    ChakraProvider,
    Box,
    Heading,
    Text,
    VStack,
    Link,
} from '@chakra-ui/react';
const Contact = () => {
return (
       <>
           <VStack spacing={4} align="center">
               <Heading>Bize Ulaşın</Heading>
               <Text >
                   Merhaba, bize aşağıdaki yollarla ulaşabilirsiniz.
               </Text>
              <Text>
                   Adresimiz: NAMIK KEMAL MAH.MÜCAHİTLER CAD.NO:62 D:1 SEYHAN /ADANA
               </Text>
               <Link href="tel:+905070004777" _hover={{
                   textDecoration: "none",

               }}>
                   <Text
                       bgGradient='linear(to-l, #FF9800, #000)'
                       bgClip='text'
                       fontSize='6xl'
                       fontWeight='extrabold'
                       style={{
                           transition: "all 0.5s ease-in-out",
                       }}
                       _hover={{
                           bgGradient:"linear(to-r, #FF9800, #000)"
                       }}
                   >
                       0(507) 000 47 77
                   </Text>
               </Link>
           </VStack>
       </>
    )
}
export default Contact;