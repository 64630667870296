import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './Catalog';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import Home from './Home';
import Menu from "./Menu";

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <>
    <Menu></Menu>
  </>
);

serviceWorker.register();

reportWebVitals(console.clear);
