import React, {useEffect, useState} from "react";
import style from "./Catalog.module.css";
import Helper from "./Helper";
import html2canvas from "html2canvas";
import {
    Box,
    Button,
    ChakraProvider,
    Divider,
    Input,
    Progress,
    SimpleGrid,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Text,
    useDisclosure,
    FormControl,
    FormLabel,
    Select,
    SliderThumb,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderMark,
    Switch,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Center,
    HStack,
    PinInput,
    PinInputField,
    useToast, useNumberInput,
} from "@chakra-ui/react";
import {PDFDocument} from "pdf-lib";
import {Buffer} from "buffer";
import {HiLockClosed, HiLockOpen} from "react-icons/hi";
import {BiBasket} from "react-icons/bi";
import {Context, ContextConsumer} from "./Context";
import {AiOutlineDelete} from "react-icons/ai";

export default function Catalog({products, logoImg}) {

    const [options, setOptions] = React.useState(localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : {
        colCount: 2,
        rowCount: 5,
        margin: 10,
        scale: 1,
        width: 595,
        height: 842,
        fontSize: 20,
        fontSizeSKU: 16,
        fontSizeName: 20,
        colorName: "#000000",
        colorPrice: "#aaff00",
        colorSku: "#ffffff",
        colorNameBg: "#ffd300",
        filigranDisplay: true,
    });

    React.useEffect(() => {
        localStorage.setItem("options", JSON.stringify(options));
    }, [options]);

    const getCount = (col) => {
        if (col === 2) {
            return 6;
        } else if (col === 3) {
            return 4;
        } else if (col === 4) {
            return 3;
        } else if (col === 6) {
            return 2;
        } else if (col === 12) {
            return 1;
        }
    }
    const [progress, setProgress] = React.useState(0);
    const [event, setEvent] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const perPage = getCount(options.colCount) * options.rowCount;
    const generatePdf = async () => {
        setEvent(true);
        let randomString = Math.random().toString(36).substring(7);
        setProgress(0);
        const totalLength = Math.ceil(products.length / perPage);
        setMessage('PDF Oluşturuluyor...');

        const pdfDoc = await PDFDocument.create();
        const pageWidth = options.width;
        const pageHeight = options.height;

        let lastCategory = "";

        for (let i = 0; i < totalLength; i++) {
            setProgress((i / totalLength) * 100);

            const cacheProductDiv = document.createElement('div');
            cacheProductDiv.className = 'row';
            cacheProductDiv.id = 'cacheProductDiv' + i;
            document.getElementById('content').appendChild(cacheProductDiv);

            const productIndex = i * perPage;

            for (let j = 0; j < perPage; j++) {
                if (products[productIndex + j]) {
                    let pr = products[productIndex + j];
                    if (lastCategory !== pr.category) {
                        lastCategory = pr.category;
                        cacheProductDiv.innerHTML += `<div class="${style.title} col-md-12"><img src="` + logoImg + `"  alt="LOGO   "/>${pr.category}<img src="` + logoImg + `"  alt="LOGO   "/></div>`;
                    }
                    cacheProductDiv.innerHTML += getHTML(pr);
                }
            }
            await html2canvas(cacheProductDiv, {
                useCORS: true,
                scale: options.scale,
            }).then(async (canvas) => {
                let urlX = canvas.toDataURL('image/png');
                const imageBytes = urlX.split(',')[1];
                const image = await pdfDoc.embedPng(Buffer.from(imageBytes, 'base64'));

                const page = pdfDoc.addPage([pageWidth, pageHeight]);
                page.drawImage(image, {
                    x: options.margin,
                    y: options.margin,
                    width: pageWidth - (options.margin * 2),
                    height: pageHeight - (options.margin * 2),
                });
            });
            document.getElementById('cacheProductDiv' + i).remove();
        }

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], {type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `ZEON MOTO KATALOG - ${randomString}.pdf`;
        link.click();

        setProgress(100);
        setMessage('PDF İNDİRİLDİ');
        setTimeout(() => {
            setEvent(false);
        }, 1000);
    };
    let lastCategoryz = ""
    const pageRef = React.useRef();
    const getHTML = (product) => {
        return `<div class="` + style.product + ` col-md-${options.colCount}">
            <img src="${product.images[0]}" alt="${product.name}" />
            <div class="${style.skuGrid}">
                <div class="${style.skuValue}" style="padding-bottom: 14px !important;">${product.sku}</div>
            </div>
            <div class="${style.productName}">
                ${product.name}
            </div>
            <div class="${style.priceGrid}" >
                <div class="${style.priceValue}" style="padding-bottom: 14px !important;">${product.price} TL</div>
            </div>
        </div>`;
    }
    const [sku, setSku] = React.useState("");
    const [productName, setProductName] = React.useState("");
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {locked,setLocked} = React.useContext(Context);
    const toast = useToast();
    const NumberINPUT = ({ func, sku }) => {
        const [value, setValue] = useState(func('getQuantity',sku));

        const handleValueChange = (e) => {
            setValue(parseInt(e.target.value));
            func('updateValue',sku, parseInt(e.target.value));
        };

        const handleIncrement = () => {
            setValue(value + 1);
            func('increment',sku)
        };

        const handleDecrement = () => {
            if (value > 1) {
                setValue(value - 1);
                func('decrement',sku)
            }else{
                func('remove',sku)
            }
        };

        return (
            <HStack px={3} bg={"white"} py={2} color={"black"}>
                <Button size={"sm"} colorScheme={"yellow"} onClick={handleDecrement}>-</Button>
                <Input
                    size={"sm"}
                    type={"number"}
                    value={value}
                    readOnly={true}
                    onChange={handleValueChange}

                    textAlign={"center"}
                />
                <Button size={"sm"} onClick={handleIncrement} colorScheme={"green"}>+</Button>
            </HStack>
        );
    };
    return (
        <>
            <div className={style.container} id={"content"} style={{
                "--priceSize": options.fontSize + "px",
                "--skuSize": options.fontSizeSKU + "px",
                "--nameSize": options.fontSizeName + "px",
                "--priceColor": options.colorPrice,
                "--skuColor": options.colorSku,
                "--nameColor": options.colorName,
                "--nameBgColor": options.colorNameBg,
                "--filigranDisplay": options.filigranDisplay ? "block" : "none",
            }}>
                <Box>



                    {!event && (<><SimpleGrid
                        columns={locked ? 2 : 4} spacing={"10px"} px={"4"} py={"5"}>
                        <Input placeholder={"STOK KODU"} value={sku}
                               onChange={(e) => setSku(String(e.target.value).toLocaleUpperCase())}/>
                        <Input placeholder={"ÜRÜN ADI"} value={productName}
                               onChange={(e) => setProductName(String(e.target.value).toLocaleUpperCase())}/>
                        {locked === false && (<><Button onClick={onOpen} colorScheme={"teal"}>Ayarlar</Button><Button
                            onClick={generatePdf}
                            colorScheme={"green"}>PDF
                            Oluştur</Button></>)}
                    </SimpleGrid></>)}
                    <Divider/>
                    {event && (<Box>
                        <Progress value={progress} isAnimated hasStripe/>
                        <Text>{message}</Text>
                    </Box>)}
                    <Drawer
                        isOpen={isOpen}
                        placement='right'
                        size={"md"}
                        onClose={onClose}
                    >
                        <DrawerOverlay/>
                        <DrawerContent>
                            <DrawerCloseButton/>
                            <DrawerHeader>Tasarım Ayarları</DrawerHeader>

                            <DrawerBody>
                                <SimpleGrid columns={1} spacing={"10px"} px={"4"} py={"5"}>
                                    <FormControl>
                                        <FormLabel>Kolon Sayısı</FormLabel>
                                        <Select value={options.colCount}
                                                onChange={(e) => setOptions({
                                                    ...options,
                                                    colCount: Number(e.target.value)
                                                })}>
                                            <option value={12}>1</option>
                                            <option value={6}>2</option>
                                            <option value={4}>3</option>
                                            <option value={3}>4</option>
                                            <option value={2}>6</option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Satır Sayısı</FormLabel>
                                        <Input placeholder={"Satır Sayısı"} type={"number"} value={options.rowCount}
                                               onChange={(e) => setOptions({
                                                   ...options,
                                                   rowCount: Number(e.target.value)
                                               })}/>
                                    </FormControl>
                                    <FormControl display='flex' alignItems='center'>
                                        <FormLabel htmlFor='email-alerts' userSelect={"none"} cursor={"pointer"}>
                                            Filigran Görünürlüğü
                                        </FormLabel>
                                        <Switch id='email-alerts' isChecked={options.filigranDisplay} onChange={(e) => {
                                            setOptions({...options, filigranDisplay: e.target.checked})
                                        }}/>
                                    </FormControl>
                                    <SimpleGrid columns={"2"} mt={"3"} spacing={"5"} spacingX={"10"}>
                                        <FormControl>
                                            <FormLabel>Ürün Kodu Font Boyutu</FormLabel>
                                            <Box w={"calc(100%-10) !important"}>
                                                <Slider colorScheme={"red"} value={options.fontSizeSKU}
                                                        onChange={(e) => setOptions({
                                                            ...options,
                                                            fontSizeSKU: Number(e)
                                                        })}
                                                >
                                                    <SliderMark value={0} mt={"5"}>
                                                        0px
                                                    </SliderMark>
                                                    <SliderMark value={90} mt={"5"}>
                                                        100px
                                                    </SliderMark>
                                                    <SliderTrack>
                                                        <SliderFilledTrack/>
                                                    </SliderTrack>
                                                    <SliderThumb boxSize={10} boxShadow={"lg"} fontSize={"sm"}
                                                                 fontWeight={"semibold"}
                                                                 bg={"red.400"}>
                                                        {options.fontSizeSKU}px
                                                    </SliderThumb>
                                                </Slider>
                                            </Box>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Ürün Kodu Rengi</FormLabel>
                                            <Input placeholder={"Renk"} type={"color"} value={options.colorSku}
                                                   onChange={(e) => setOptions({
                                                       ...options,
                                                       colorSku: e.target.value
                                                   })}/>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Fiyat Font Boyutu</FormLabel>
                                            <Box w={"calc(100%-10) !important"}>
                                                <Slider colorScheme={"teal"} value={options.fontSize}
                                                        onChange={(e) => setOptions({...options, fontSize: Number(e)})}
                                                >
                                                    <SliderMark value={0} mt={"5"}>
                                                        0px
                                                    </SliderMark>
                                                    <SliderMark value={90} mt={"5"}>
                                                        100px
                                                    </SliderMark>
                                                    <SliderTrack>
                                                        <SliderFilledTrack/>
                                                    </SliderTrack>
                                                    <SliderThumb boxSize={10} boxShadow={"lg"} fontSize={"sm"}
                                                                 fontWeight={"semibold"}
                                                                 bg={"teal.400"}>
                                                        {options.fontSize}px
                                                    </SliderThumb>
                                                </Slider>
                                            </Box>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Ürün Fiyat Rengi</FormLabel>
                                            <Input placeholder={"Renk"} type={"color"} value={options.colorPrice}
                                                   onChange={(e) => setOptions({
                                                       ...options,
                                                       colorPrice: e.target.value
                                                   })}/>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Ürün Adı Font Boyutu</FormLabel>
                                            <Box w={"calc(100%-10) !important"}>
                                                <Slider colorScheme={"blue"} value={options.fontSizeName}
                                                        onChange={(e) => setOptions({
                                                            ...options,
                                                            fontSizeName: Number(e)
                                                        })}
                                                >
                                                    <SliderMark value={0} mt={"5"}>
                                                        0px
                                                    </SliderMark>
                                                    <SliderMark value={90} mt={"5"}>
                                                        100px
                                                    </SliderMark>
                                                    <SliderTrack>
                                                        <SliderFilledTrack/>
                                                    </SliderTrack>
                                                    <SliderThumb boxSize={10} boxShadow={"lg"} fontSize={"sm"}
                                                                 fontWeight={"semibold"}
                                                                 bg={"blue.400"}>
                                                        {options.fontSizeName}px
                                                    </SliderThumb>
                                                </Slider>
                                            </Box>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Ürün Adı Rengi</FormLabel>
                                            <Input placeholder={"Renk"} type={"color"} value={options.colorName}
                                                   onChange={(e) => setOptions({
                                                       ...options,
                                                       colorName: e.target.value
                                                   })}/>
                                        </FormControl>

                                    </SimpleGrid>
                                    <FormControl mt={"5"}>
                                        <FormLabel>Ürün Adı Arka Plan Rengi</FormLabel>
                                        <Input placeholder={"Renk"} type={"color"} value={options.colorNameBg}
                                               onChange={(e) => setOptions({...options, colorNameBg: e.target.value})}/>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Dış Boşluk px (Oynamayınız)</FormLabel>
                                        <Input placeholder={"Margin"} value={options.margin} type={"number"}
                                               onChange={(e) => setOptions({
                                                   ...options,
                                                   margin: Number(e.target.value)
                                               })}/>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Kalite 0.1 {">"} 2</FormLabel>
                                        <Input placeholder={"Scale"} type={"number"} value={options.scale}
                                               onChange={(e) => setOptions({
                                                   ...options,
                                                   scale: Number(e.target.value)
                                               })}/>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Genişlik px (Oynamayınız)</FormLabel>
                                        <Input placeholder={"Width"} type={"number"} value={options.width}
                                               onChange={(e) => setOptions({
                                                   ...options,
                                                   width: Number(e.target.value)
                                               })}/>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Yükseklik px (Oynamayınız)</FormLabel>
                                        <Input placeholder={"Height"} type={"number"} value={options.height}
                                               onChange={(e) => setOptions({
                                                   ...options,
                                                   height: Number(e.target.value)
                                               })}/>
                                    </FormControl>

                                </SimpleGrid>

                            </DrawerBody>

                            <DrawerFooter>
                                <Button variant='outline' mr={3} onClick={onClose}>
                                    Kapat
                                </Button>
                                <Button colorScheme='red' onClick={() => {
                                    setOptions({
                                        colCount: 2,
                                        rowCount: 5,
                                        margin: 10,
                                        scale: 1,
                                        width: 595,
                                        height: 842,
                                        fontSize: 20,
                                        fontSizeSKU: 16,
                                        fontSizeName: 20,
                                        colorName: "#000000",
                                        colorPrice: "#aaff00",
                                        colorSku: "#ffffff",
                                        colorNameBg: "#ffd300",
                                        filigranDisplay: true,
                                    })
                                }}>Sıfırla</Button>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </Box>

                {!event && (<div className={"row " + style.rowA} style={{
                    margin: "0px !important",
                }} ref={pageRef}>
                    {products.map((product, index) => {
                        let farkli = false;
                        if (lastCategoryz !== product.category) {
                            farkli = true;
                        }
                        lastCategoryz = product.category;
                        let visible = true;
                        if (sku !== "") {
                            if (product.sku.indexOf(sku) === -1) {
                                visible = false;
                            }
                        }
                        if (productName !== "") {
                            if (product.name.indexOf(productName) === -1) {
                                visible = false;
                            }
                        }
                        return (<>

                            {farkli && (
                                <div className={`${style.title} ${visible ? "" : style.none} col-md-12`}
                                     key={index + "title"}>
                                    <img src={logoImg} alt="LOGO   "/>
                                    {product.category}
                                    <img src={logoImg} alt="LOGO   "/>
                                </div>
                            )}
                            <div
                                className={`${style.product} ${visible ? "" : style.none} col-md-${options.colCount} relative`}
                                key={product.id}>
                                <ContextConsumer>
                                    {({controlBasket, addBasket, removeBasket}) => {
                                        return (<>

                                            <Box position={"absolute"} top={"5px"} right={"20px"}>
                                                <IconButton title={controlBasket[product.sku] !== undefined ?"Sepetten Çıkar":"Sepete Ekle"} icon={controlBasket[product.sku] !== undefined ? <AiOutlineDelete/>  :<BiBasket />} onClick={() => {
                                                    if (controlBasket[product.sku] !== undefined) {
                                                        removeBasket(product.sku)
                                                    } else {
                                                        addBasket({
                                                            sku: product.sku,
                                                            name: product.name,
                                                            price: product.price,
                                                            image: product.images[0],
                                                        })
                                                    }
                                                }}
                                                            colorScheme={controlBasket[product.sku] !== undefined ? "red" : "green"} rounded={"full"}
                                                            aria-label={"Sepete Ekle"}/>
                                            </Box>


                                        </>)
                                    }}

                                </ContextConsumer>
                                <img src={product.images[0]} alt={product.name}/>
                                {/*<div className={style.image} style={{*/}
                                {/*  "--image":`url("${product.images[0]}")`*/}
                                {/*}}></div>*/}

                                <div className={style.skuGrid} style={{position:"relative"}}>
                                    <ContextConsumer>
                                        {({controlBasket,updateQuantity}) => (<>
                                            {controlBasket[product.sku] !== undefined && (<>
                                                <Box position={"absolute"} top={"-45px"} left={0} right={0}>
                                                    <NumberINPUT sku={product.sku} func={updateQuantity} />
                                                </Box>
                                            </>)}
                                        </>)}
                                    </ContextConsumer>
                                    <span className={style.skuValue}>{product.sku}</span>
                                </div>
                                <div className={style.productName}>
                                    {product.name}
                                </div>
                                <div className={style.priceGrid}>
                                    <span className={style.priceValue}>{!locked ? product.price + " TL" : ""} </span>
                                </div>
                            </div>
                        </>)
                    })}
                </div>)}
            </div>
        </>
    );
}