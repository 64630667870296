class Helper{
  static EP = process.env.REACT_APP_EP;
  static CUSTOMER = process.env.REACT_APP_CUSTOMER;
  static async getActiveProducts(){
    const response = await fetch(
      `${(Helper.EP)}/products/active?customer=`+Helper.CUSTOMER
    );
    return await response.json();
  }
}
export default Helper;